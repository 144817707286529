.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  h1 {
    font-weight: 800;
  }
  h2 {
    font-weight: 700;
  }
  h3 {
    font-weight: 600;
  }
  h4 {
    font-weight: 500;
  }
  h5 {
    font-weight: 400;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }
}

/* styles.css */
.CollapsibleContent[data-state='open'] {
  animation: collapse-slide-down 250ms ease-in-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: collapse-slide-up 250ms ease-in-out;
}

@keyframes collapse-slide-down {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 100;
  }
}

@keyframes collapse-slide-up {
  from {
    height: var(--radix-collapsible-content-height);
    opacity: 100;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.AccordionTrigger[data-state='open'] > svg {
  transform: rotate(180deg);
}

/* .AccordionTrigger[data-state='open'] {
  background: #000000;
  border-color: transparent;
}

.AccordionTrigger[data-state='open'] span {
  color: white;
}

.AccordionTrigger[data-state='open'] svg {
  fill: white;
} */

.AccordionContent[data-state='open'] {
  animation: AccordionSlideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: AccordionSlideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

/* .AccordionContent[data-state='open'] > div button {
  opacity: 0;
}

.AccordionContent[data-state='open'] > div button {
  opacity: 1;
} */

@keyframes AccordionSlideDown {
  from {
    height: 0;
    /* opacity: 0; */
  }
  to {
    height: var(--radix-accordion-content-height);
    /* opacity: 1; */
  }
}

@keyframes AccordionSlideUp {
  from {
    height: var(--radix-accordion-content-height);
    /* opacity: 1; */
  }
  to {
    height: 0;
    /* opacity: 0; */
  }
}

.ProgressRoot {
  transform: translateZ(0);
}
.ProgressIndicator {
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

.pulse {
  animation: opace 1.7s ease-in-out infinite;
}

@keyframes opace {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.5;
  }
}

.spinner {
  fill: #fff;
}

@media (prefers-color-scheme: dark) {
  .spinner {
    fill: #000;
  }
}
